window.addEventListener('DOMContentLoaded', () => {
    /**
     * Sticky table component.
     *
     * @param {WptbTableObject} prop0 table object
     * @param {HTMLTableElement} prop0.mainTable target table
     * @param {Node} prop0.matrixContainer matrix container element
     * @param {Node} prop0.parentContainer parent container element
     * @constructor
     */
    function StickyTableComponent({mainTable, matrixContainer, parentContainer}) {
        /**
         * Default background color for cells.
         * @type {string}
         */
        const defaultCellBg = 'rgb(1,1,1)';

        /**
         * Cloned and stickied table.
         * @type {null | HTMLTableElement}
         */
        this.stickyTable = null;

        /**
         * First column cells in original table.
         * @type {Array<HTMLTableCellElement>}
         */
        this.originalFirstColumnCells = [];

        /**
         * First column cells of sticky table.
         * @type {Array<HTMLTableCellElement>}
         */
        this.stickyFirstColumnCells = [];

        /**
         * Calculate overall width for first column of table.
         * Final value will be fixed to 2 fraction digits.
         */
        const calculateFirstColumnWidth = () => {
            const firstColumCell = mainTable.querySelector('td');
            return firstColumCell.getBoundingClientRect().width.toFixed(2);
        }

        /**
         * Calculate cell heights of sticky cells.
         */
        const calculateStickyCellHeights = () => {
            setTimeout(() => {
                this.originalFirstColumnCells.map((originalCell, originalCellIndex) => {
                    const {height: originalCellHeight} = window.getComputedStyle(originalCell);

                    this.stickyFirstColumnCells[originalCellIndex].style.height = originalCellHeight;
                })
            }, 100);
        }

        /**
         * Sticky table style and position calculations
         */
        const stickyStyleAndPosition = () => {
            // calculate width
            this.stickyTable.style.width = `${calculateFirstColumnWidth(mainTable)}px`;
            this.stickyTable.style.minWidth = 'unset';

            this.stickyTable.classList.add('wptb-pro-sticky-column-table');

            // calculate position
            const {paddingLeft, paddingTop} = window.getComputedStyle(matrixContainer);
            this.stickyTable.style.left = paddingLeft;
            this.stickyTable.style.top = paddingTop;

            // calculate height of sticky cells
            calculateStickyCellHeights();
        }


        /**
         * Get opacity of given rgb/rgba color.
         * @param {String} colorValue rgb/rgba color value
         *
         * @return {Number} opacity value
         */
        const colorOpacity = (colorValue) => {
            const match = colorValue.match(/(?<type>.+)\((.+\,\s?(?<opacity>.+)\s?)\)/);

            const {type, opacity} = match?.groups;

            if (type && type === 'rgba') {
                if (opacity) {
                    return Number.parseInt(opacity, 10);
                }
            }

            return 1;
        }

        /**
         * Find suitable background color for elements.
         * @param {Node} element element
         * @return {String} calculated element bg color
         */
        const calculateElementBg = (element) => {
            // if element is document root, assign default color
            if (element === document.documentElement) {
                return defaultCellBg;
            }

            const {backgroundColor} = window.getComputedStyle(element);
            const opacity = colorOpacity(backgroundColor);

            if (opacity === 1) {
                return backgroundColor;
            }

            return calculateElementBg(element.parentNode);
        }


        /**
         * Assign cell background color if they are transparent.
         * @param {HTMLTableCellElement} cell table cell
         */
        const assignCellBg = (cell) => {
            cell.style.backgroundColor = calculateElementBg(cell);
        }

        /**
         * Get first column cells of target table.
         * @param {HTMLTableElement} targetTableElement target table
         * @return {Array<HTMLTableCellElement>} first column cells
         */
        const getFirstColumnCells = (targetTableElement) => {
            // index to mark rows with first column, will be used to identify and support cells with rowSpan values higher than 1
            let firstColumnRowIndex = 0;

            let firstColumnCells = [];

            Array.from(targetTableElement.querySelectorAll('tr')).map((rowElement, rowIndex) => {
                Array.from(rowElement.querySelectorAll('td')).map((cell, cellIndex) => {
                    if (firstColumnRowIndex === rowIndex) {
                        if (cellIndex === 0) {
                            let rowspanAttr = cell.rowSpan ? Number.parseInt(cell.rowSpan, 10) : 1;

                            firstColumnRowIndex += rowspanAttr;
                            firstColumnCells.push(cell);
                        }
                    }
                })
            })

            return firstColumnCells;
        }

        /**
         * Generate sticky clone of the first column.
         */
        const generateStickyTableClone = () => {
            this.originalFirstColumnCells = getFirstColumnCells(mainTable);

            // assign cell background colors especially to support transparent rows/cells
            this.originalFirstColumnCells.map(assignCellBg);

            const clonedTable = mainTable.cloneNode(true);

            this.stickyFirstColumnCells = getFirstColumnCells(clonedTable);


            Array.from(clonedTable.querySelectorAll('tr')).map((rowElement) => {
                Array.from(rowElement.querySelectorAll('td')).map((cell) => {
                    if (!this.stickyFirstColumnCells.includes(cell)) {
                        rowElement.removeChild(cell);
                    }
                })
            })

            return clonedTable;
        }

        /**
         * Operations related to table matrix container.
         */
        const matrixContainerOperations = () => {
            matrixContainer.classList.add('wptb-pro-sticky-matrix-container');
            matrixContainer.insertAdjacentElement('beforeend', this.stickyTable);
        }

        /**
         * Add listener to various events.
         */
        const attachToEvents = () => {
            parentContainer.addEventListener('scroll', () => {
                const verticalScrollAmount = parentContainer.scrollLeft;
                this.stickyTable.dataset.scrolled = verticalScrollAmount > 0;
                this.stickyTable.style.left = `${verticalScrollAmount}px`;
            }, true);
        }

        /**
         * Set visibility for sticky table.
         * @param {boolean} status visibility status
         */
        this.setVisibility = (status) => {
            this.stickyTable.dataset.visible = status;
        }

        /**
         * Check for disruptions that may change dimensions of table after loaded.
         *
         * @return {Promise} promise object which will be resolved when all layout disruptions are resolved
         */
        const checkLayoutDisruptions = () => {
            const tableImages = Array.from(this.stickyTable.querySelectorAll('img'));

            const promises = tableImages.map(imageElement => {
                return new Promise(res => {
                    if (imageElement.complete && imageElement.naturalHeight !== 0) {
                        return res();
                    } else {
                        imageElement.addEventListener('loaded', () => {
                            return res();
                        })
                        imageElement.addEventListener('error', () => {
                            return res();
                        })
                    }
                })
            })

            return Promise.all(promises);
        }

        /**
         * Initialize component.
         */
        this.init = () => {
            // generate sticky table element
            // in order to support recalculation of sticky table properties upon events (e.g. window resize), check for the availability of an already generated sticky table
            if (!this.stickyTable) {
                this.stickyTable = generateStickyTableClone();
            }

            checkLayoutDisruptions().then(() => {
                // wait period to give browser time to paint images to DOM
                setTimeout(() => {
                    // style and position related operations
                    stickyStyleAndPosition();

                    // matrix container related operations
                    matrixContainerOperations();

                    // attach to various events
                    attachToEvents();
                }, 500);
            })
        }
    }

    /**
     * WPTB sticky column.
     * @constructor
     */
    function WptbStickyColumn() {
        /**
         * Attach to various events to support functionality
         *
         * @param {StickyTableComponent} stickyComponent sticky component
         */
        const attachToEvents = (stickyComponent) => {
            window.addEventListener('resize', () => {
                stickyComponent.setVisibility(!wptbResponsiveFrontendInstance.isResponsiveEnabledForCurrentBreakpoint(stickyComponent.stickyTable));
            })

        }

        /**
         * Start sticky column operation.
         *
         * @param {WptbTableObject} tableObject table object
         */
        const startOperation = (tableObject) => {
            const {mainTable} = tableObject;

            const responsiveStatus = wptbResponsiveFrontendInstance.isResponsiveEnabledForCurrentBreakpoint(mainTable);

            const stickyStatus = mainTable.dataset.wptbFirstColumnSticky === 'true' || mainTable.dataset.wptbFirstColumnSticky === '1';

            // only initialize sticky column operations if responsive mode is disabled and operation is enabled for target table
            if (!responsiveStatus && stickyStatus) {
                const stickyComponent = new StickyTableComponent(tableObject);
                stickyComponent.init();
                attachToEvents(stickyComponent);
            }
        }

        /**
         * Initialize sticky column.
         */
        this.init = () => {
            WptbFrontendBase.getTableObjects().map(startOperation);
        }
    }

    // initialize sticky column
    new WptbStickyColumn().init();
})
